<template>
    <div class="search">
        <div class="search_nav">
            <ul class="search_nav_list">
                <li>
                    <div class="search_nav_list_name yh-lh36 yh-fw600">工种类别:</div>
                    <ul class="search_nav_list_ul flex align_center">
                        <li
                            class="yh-lh36"
                            v-for="(item, index) in classList"
                            :class="{active:index == i}"
                            :key="index"
                            @click="changeI(index)"
                        >{{item.name}}</li>
                    </ul>
                </li>
            </ul>
        </div>
        <div class="search_main">
            <ul class="home_course_main_list">
                <li
                    v-for="(item, index) in courseList"
                    :key="index"
                    @click="goCourseMessage(item.id)"
                >
                    <div class="home_course_main_list_img">
                        <img :src="item.image" draggable="false" alt />
                        <span>学习人数: {{item.show_user_num || item.user_num || 0}}</span>
                    </div>
                    <div class="home_course_main_list_txt">
                        <div class="home_course_main_list_name">{{item.name}}</div>
                        <div class="home_course_main_list_title flex flex_between align_center">
                            <div class="home_course_main_list_title_l flexs">
                                <span>学时：</span>
                                <span>{{item.hours || 0}}</span>
                            </div>
                            <span class="yh-fz14 yh-fc999">|</span>
                            <div class="home_course_main_list_title_l flexs">
                                <span>{{item.tag}}</span>
                            </div>
                            <div
                                v-if="!item.is_paid || item.is_paid == 0"
                                class="home_course_main_list_title_btn flex align_center flex_center yh-ml20"
                                @click.stop="applySubmit(item)"
                            >立即报名</div>
                            <div
                                v-if="item.is_paid && item.is_paid == 1"
                                class="home_course_main_list_title_btn flex align_center flex_center yh-ml20"
                                @click.stop="pageJumps('courseDetails',{'id':item.id},true)"
                            >继续学习</div>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="search_page flex align_center flex_center" v-if="courseList.length > 0">
                <el-pagination
                    class="center_combo yh-h88"
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="page"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="20"
                    layout="prev, pager, next"
                    :total="total"
                ></el-pagination>
            </div>
        </div>
        <apply ref="apply"></apply>
        <Error v-show="!haveList" />
    </div>
</template>

<script>
import apply from "@/components/apply";
export default {
    name: "search",
    components: {
        apply,
    },
    data() {
        return {
            i: 0,
            page: 1,
            total: 0, //总条数
            classList: [], //分类列表
            courseList: [],
            haveList: true, // 有列表数据
        };
    },
    computed: {
        searchVal() {
            return this.$store.getters.getSeachValue;
        },
    },
    watch: {
        searchVal() {
            this.getCourseList();
        },
    },
    methods: {
        //去课程详情
        goCourseMessage(id) {
            if (this.judgeIsLogin()) {
                this.$router.push({ name: "CourseMessage", query: { id } });
            }
        },
        //切换分类
        changeI(index) {
            if (index == 0) {
                if (!this.searchVal) {
                    return this.$message({
                        message: "请输入搜索的课程名称",
                        type: "warning",
                    });
                }
            } else {
                this.i = index;
                this.$store.commit("upSearchValue", "");
            }
            this.page = 1;
            this.getCourseList();
        },
        // 立即报名
        applySubmit(item) {
            if (this.judgeIsLogin()) {
                this.$refs.apply.show(item);
            }
        },
        handleSizeChange(val) {
            this.page = val;
            this.getCourseList();
        },
        handleCurrentChange(val) {
            this.page = val;
            this.getCourseList();
        },
        //获取搜索课程列表
        getCourseList() {
            if (this.searchVal) {
                this.i = 0;
                this.$axios
                    .post("/api/index/search", {
                        name: this.searchVal,
                        page: this.page,
                    })
                    .then((res) => {
                        if (res.code === 1) {
                            this.courseList = res.data.data;
                            this.total = res.data.total;
                        }
                        if (this.courseList.length) {
                            this.haveList = true;
                        } else {
                            this.haveList = false;
                        }
                    });
            } else {
                let api = "";
                if (!this.judgeIsLogin(false)) {
                    api = this.$api.courseList;
                } else {
                    api = this.$api.dCourseList;
                }
                this.$axios
                    .post(api, {
                        cid: this.classList[this.i].id,
                        page: this.page,
                    })
                    .then((res) => {
                        if (res.code === 1) {
                            this.courseList = res.data.data;
                            this.total = res.data.total;
                        }
                        if (this.courseList.length) {
                            this.haveList = true;
                        } else {
                            this.haveList = false;
                        }
                    });
            }
        },
        //获取分类列表
        getClassIfyList() {
            this.$axios.post("/api/index/courseCategoryList").then((res) => {
                if (res.code === 1) {
                    let obj = {
                        name: "搜索的课程",
                        id: null,
                    };
                    let obj1 = {
                        name: "全部",
                        id: -1,
                    };
                    this.classList = res.data.data;
                    this.classList.unshift(obj1);
                    this.classList.unshift(obj);
                    if (this.searchVal) {
                        this.i = 0;
                    } else {
                        this.i = 1;
                    }
                    this.getCourseList();
                }
            });
        },
    },
    created() {
        this.getClassIfyList();
    },
};
</script>

<style scoped lang="scss">
.search {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 60px;
    .search_nav {
        padding-top: 20px;
        margin-bottom: 10px;
        .search_nav_list {
            width: 1200px;
            background: #ffffff;
            border: 1px solid #f2f2f2;
            li {
                display: flex;
                padding: 30px 40px 20px 10px;
                border-bottom: 1px dashed #efefef;
                &:last-child {
                    border-bottom: none;
                }
                .search_nav_list_name {
                    color: #999999;
                    font-size: 14px;
                    flex-shrink: 0;
                    margin-right: 20px;
                }
                .search_nav_list_ul {
                    flex-wrap: wrap;
                    li {
                        padding: 0;
                        font-size: 14px;
                        cursor: pointer;
                        margin-right: 20px;
                        border-bottom: none;
                    }
                    .active {
                        color: #1263ff;
                        font-weight: bold;
                    }
                }
            }
        }
    }
    .search_main {
        .home_course_main_list {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 90px;
            li {
                width: 227px;
                cursor: pointer;
                background: #ffffff;
                box-shadow: 0px 0px 10px 0px rgba(211, 211, 211, 0.3);
                border-radius: 4px;
                margin-right: 15px;
                margin-top: 20px;
                &:nth-child(5n) {
                    margin-right: 0;
                }
                .home_course_main_list_img {
                    width: 227px;
                    height: 170px;
                    position: relative;
                    img {
                      object-fit: cover;
                        border-radius: 4px 4px 0px 0px;
                    }
                    span {
                        color: #fefefe;
                        font-size: 12px;
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        text-align: center;
                        line-height: 32px;
                        background: linear-gradient(
                            0deg,
                            rgba(0, 0, 0, 0.6),
                            rgba(0, 0, 0, 0)
                        );
                    }
                }
                .home_course_main_list_txt {
                    padding: 15px 10px;
                    .home_course_main_list_name {
                        font-size: 14px;
                        overflow: hidden;
                        width: 200px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .home_course_main_list_title {
                        margin-top: 10px;
                        .home_course_main_list_title_l {
                            span {
                                font-size: 12px;
                                &:first-child {
                                    color: #999999;
                                }
                                &:last-child {
                                    color: #1263ff;
                                    margin-left: 5px;
                                }
                            }
                        }
                        .home_course_main_list_title_btn {
                            width: 76px;
                            height: 29px;
                            color: #ffffff;
                            font-size: 12px;
                            background: #1263ff;
                            border-radius: 15px;
                        }
                    }
                }
            }
        }
    }
    .search_error {
        margin-top: 178px;
    }
}
</style>
